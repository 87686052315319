<template>
  <div class="page">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div class="list">
        <div
          class="item"
          @click="toDetail('propertyNoticeDetail', item.id)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="item-left">
            <img :src="item.topicPicture" alt="" />
          </div>
          <div class="item-right">
            <div class="name">
              {{ item.topicTitle || "" }}
            </div>
            <div class="time">2022-03-19 08:52:29</div>
            <div class="detailInfo">
              <img src="./img/look.png" />
              <div>{{ item.readCount }}</div>
              <img src="./img/message.png" />
              <div>{{ item.commentCount }}</div>
            </div>
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { getTopicListURL } from "./api.js";
import { mapState } from "vuex";
export default {
  name: "propertyNoticeList",
  data() {
    return {
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curPage: 1,
      pageSize: 20,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    onLoad() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            tenantId: this.tenantId,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 23, // 2:社区超话；15:居民超话
            curPage: this.curPage,
            pageSize: this.pageSize,
            houseId: this.houseId || this.communityId || undefined,
            sortField: "NEW", // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.listData.forEach((ele) => {
              if (ele.topicPicture) {
                ele.topicPicture = this.$handleImg(220, 220, ele.topicPicture);
              }
            });
            this.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    toDetail(name, id) {
      this.$router.push({
        name: name,
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  .list {
    padding: 30px 32px;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 220px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      margin-bottom: 20px;
      .item-left {
        width: 220px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-right {
        flex: 1;
        height: 100%;
        padding: 28px 32px;
        box-sizing: border-box;
        .name {
          width: 400px;
          font-size: 32px;
          font-weight: 600;
          color: #333333;
          margin-bottom: 42px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .time {
          font-size: 26px;
          color: rgba(0, 0, 0, 0.25);
          margin-bottom: 10px;
        }
        .detailInfo {
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.25);
          img {
            width: 34px;
          }
          div {
            margin-left: 8px;
            margin-right: 30px;
          }
        }
      }
    }
  }
}
</style>
